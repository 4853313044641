import React from 'react'

function Footer() {
  return (
    <div style={{position:'fixed',bottom:'0',left:'0',right:'0',background:'lightgray',padding:'15px'}}>
<a href='https://yogesh-bathe.xyz'>yogesh-bathe.xyz</a>
    </div>
  )
}

export default Footer