import React, { useEffect, useRef, useState } from 'react'
import Footer from './Footer';

function Todolist() {
  // localStorage.clear();
  let localExpences = (localStorage.getItem('expences')) ? localStorage.getItem('expences') : []
  let localAmount = (localStorage.getItem('amount')) ? localStorage.getItem('amount') : ''
  localExpences = (localStorage.getItem('expences')) ? JSON.parse(localExpences) : [];
  const [salary, setSalary] = useState(localAmount);
  // const [inputSal, setInputSal] = useState();
  const [inputExpenses, setInputExpenses] = useState('');
  const [inputAmt, setInputAmt] = useState('');
  const [Expenses, setExpenses] = useState(localExpences);
  const [id, setId] = useState(1);
  const [total, setTotal] = useState();
  const inputRef = useRef()
  // console.log(Expenses)
  // localStorage.setItem('expence', JSON.stringify([{ id: '4', exp: 'milk', amt: 890 }, { id: '5', exp: 'mobile', amt: 10000 }]))
  useEffect(() => {
    setTotal(Expenses.reduce((total, item) => Number(total) + Number(item.amt), 0))
  }, [Expenses])
  function setInputAmtFunction(e) {
    setSalary(e.target.value)
    localStorage.setItem('amount', e.target.value)
  }
  const addExpenses = () => {
    // console.log(inputExpenses,inputAmt)
    if (salary == '') {
      alert('Please enter salary first')
      return;
    }
    if (inputExpenses == '' || inputAmt == '') {
      alert('Please enter Expenses name & Expenses amount')
      return;
    }
    setId(id + 1)
    setExpenses([...Expenses, { id: id, exp: inputExpenses, amt: inputAmt }])
    localStorage.setItem('expences', JSON.stringify([...localExpences, { id: id, exp: inputExpenses, amt: inputAmt }]))
    totalExpenses()
    setInputExpenses('')
    setInputAmt('')
    inputRef.current.focus();
  }
  function totalExpenses() {
    setTotal(Expenses.reduce((total, item) => Number(total) + Number(item.amt), inputAmt))
  }
  // console.log(Expenses)
  function deleteHandler(id) {
    let [selectedExpenses] = Expenses.filter((item) => item.id == id)
    if (window.confirm(`Are you sure you want to delete " ${selectedExpenses.exp} " Expense?`)) {
      setExpenses(Expenses.filter((item) => item.id !== id))
      let arrays = Expenses.filter((item) => item.id !== id)
      // console.log(arrays)
      localStorage.setItem('expences',JSON.stringify(arrays))
    }
  }
  function resetHandler() {
    if (window.confirm('Are you sure you want to reset Tracker?')) {
      setExpenses([])
      setSalary('')
      localStorage.clear();
    }
  }
  let salaryValue = (salary) ? salary - total : 0
  salaryValue = parseFloat(salaryValue).toFixed(2);
  let plus = 'green'
  let minus = 'red';
  let balanceColor = (salaryValue < 0) ? minus : plus;
  return (
    <div>
      <div className='row' style={{ border: '1px solid gray', background: 'floralwhite', padding: '20px 0' }}>
        <h3 style={{ padding: '20px 0px', textDecoration: 'underline' }}>Your Expenses Tracker</h3>
        <div className='row justify-content-center'>
          <div className='col-sm-1 col-xs-12 col-md-3'>
            <div className="input-group mb-3">
              <input style={{textAlign:'right'}} type='text' onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }} value={salary} placeholder='Enter Your Salary (ie:40000)' title='Enter Your Salary (ie:40000)' maxLength={10} className='form-control' onChange={setInputAmtFunction} /><span class="input-group-text">Rs.</span>
              {/* <button className='btn btn-success' onClick={() => setSalary(inputSal)}>Add Salary</button><br /> */}
            </div>
          </div>
        </div>

        <div className='row justify-content-center'>
          <div className='col-sm-8 col-xs-12'>
            <div className="input-group mb-3">
              <input className='form-control' ref={inputRef} style={{ width: '300px' }} title='Expenses Name (ie:Petrol, Shopping, Fees, Newspaper, Milk bill.....)' maxLength={100} placeholder='Expenses Name (ie:Petrol, Shopping, Fees, Newspaper, Milk bill.....)' type='text' value={inputExpenses} onChange={(e) => setInputExpenses(e.target.value)} />
              <span class="input-group-text">&nbsp;</span>
              <input className='form-control col-xs-3' onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }} maxLength={10} placeholder='Paid Amount' title='Paid Amount' type='text' value={inputAmt} onChange={(e) => setInputAmt(e.target.value)} />
              <button className='btn btn-success' onClick={() => { addExpenses() }}>Add Expense</button>
            </div>
          </div>

          <h4 style={{color:'blueviolet'}}>Your Entered Salary is : {Number(salary).toLocaleString()} Rs.</h4>
          <div className='col-sm-9 col-xs-12'>
            <div className='table-responsive'>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th style={{ backgroundColor: 'lightgray' }} scope="col">#</th>
                    <th style={{ backgroundColor: 'lightgray' }} className='w-100' scope="col">Expenses Name</th>
                    <th style={{ backgroundColor: 'lightgray' }} scope="col">Amt (Rs.)</th>
                    <th style={{ backgroundColor: 'lightgray' }} scope="col">Expences (%)</th>
                    <th style={{ backgroundColor: 'lightgray' }} scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    (Expenses.length > 0) ? (Expenses.map((item, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td className='capitalize' style={{ textAlign: 'left', textIndent: '5px' }}>{item.exp}</td>
                          <td style={{ textAlign: 'right' }}>{Number(item.amt).toLocaleString()}</td>
                          <td style={{ textAlign: 'right' }}>{(item.amt && salary)?(Number(item.amt)*100/salary).toFixed(2)+'%':'-'}</td>
                          <td><button className='btn btn-danger btn-sm' onClick={() => deleteHandler(item.id)}>Delete</button></td>
                        </tr>
                      )
                    })
                    ) : (<tr><td></td><td>No Data Found</td><td></td><td></td><td></td></tr>)
                  }

                </tbody>
                <tfoot>
                  {/* <tr><th style={{textAlign:'right'}} colSpan={2}>Salary:</th><th>{Number(salary).toLocaleString()}</th></tr> */}
                  <tr>
                    <th style={{ textAlign: 'right', background: 'lightyellow' }} colSpan={2}>Total Expenses:</th>
                    <th style={{ textAlign: 'right', background: 'lightyellow' }}>{Number(total).toLocaleString()}</th>
                    <th style={{ textAlign: 'right',background: 'lightyellow' }}>{(total && salary)?(Number(total)*100/salary).toFixed(2)+'%':'-'}</th>
                    <th style={{ background: 'lightyellow' }}>-</th>
                  </tr>
                  <tr>
                    <th style={{ textAlign: 'right', background: 'lightyellow' }} colSpan={2}>Salary Balance:</th>
                    <th style={{ textAlign: 'right', background: 'floralwhite', color: balanceColor }}>{Number(salaryValue).toLocaleString()}</th>
                    <th style={{ textAlign: 'right', background: 'floralwhite', color: balanceColor }}>{(total && salary)?(Number(salary-total)*100/salary).toFixed(2)+'%':'-'}</th>
                    <th style={{ background: 'lightyellow' }}>-</th>
                  </tr>
                  <tr><td style={{paddingBottom:'30px'}} colSpan={5}><button className='btn btn-success' onClick={resetHandler}>Reset Tracker</button></td></tr>
                </tfoot>
              </table>
            </div>
          </div>

        </div>
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default Todolist